import React from "react"
import { Button } from "antd"
import styled from "styled-components"
import { HtmlType } from "../../../constants"
import { theme } from "../../../theme"

interface ButtonProps {
  className?: string
  label?: string
  borderRadius?: string
  backgroundColor?: string
  color?: string
  height?: string
  width?: string
  minWidth?: string
  margin?: string
  bold?: boolean
  htmlType?: HtmlType
  loading?: boolean
  onClick?: any
  icon?: any
  disabled?: boolean
  type?: string
}

const heightDefault = "60px"
const minWidthDefault = "120px"

const StyledButton = styled.div<ButtonProps>`
  .ant-btn {
    border: none;
    padding: 0px 15px;

    &[disabled] {
      background-color: ${theme.background.disabledColor};
      color: ${theme.font.lightBlack};
    }

    border-radius: ${({ borderRadius }: ButtonProps) => {
      return borderRadius ? borderRadius : theme.border.radiusPrimary
    }};
    background-color: ${({ backgroundColor, type }: ButtonProps) =>
      type === "business"
        ? theme.background.darkOrange
        : backgroundColor
        ? backgroundColor
        : theme.background.colorPrimary};

    color: ${({ color }: ButtonProps) => {
      return color ? color : theme.font.colorSecondary
    }};
    height: ${({ height }: ButtonProps) => {
      return height ? height : heightDefault
    }};
    width: ${({ width }: ButtonProps) => {
      return width ? width : `auto`
    }};
    min-width: ${({ minWidth }: ButtonProps) => {
      return minWidth ? minWidth : minWidthDefault
    }};
    margin: ${({ margin }: ButtonProps) => {
      return margin && margin
    }};
    font-weight: ${({ bold }: ButtonProps) => {
      return bold && "bold"
    }};
    :hover {
      border-color: transparent;
    }
  }
`

const ButtonComponent: React.FC<ButtonProps> = ({
  label,
  htmlType,
  loading,
  onClick,
  icon,
  disabled,
  type,
  ...props
}) => {
  return (
    <StyledButton {...props} type={type}>
      <Button
        className={"button"}
        onClick={onClick}
        htmlType={htmlType}
        loading={loading}
        icon={icon}
        disabled={disabled}
        {...props}
      >
        {label}
      </Button>
    </StyledButton>
  )
}

export { ButtonComponent }
