import { Modal } from "antd"
import React from "react"
import styled from "styled-components"
import { Button, ErrorLabel, InputField } from "../../atom"
import { theme } from "../../../theme"
import { useFormik } from "formik"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { HtmlType } from "../../../constants"

const StyledModal = styled(Modal)`
  form.passwordModalForm {
    margin-top: 10px;
    div:first-child {
      margin-bottom: 12px;
    }
  }
`
const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 10px;
  gap: 15px;
`

interface IPasswordReset {
  password?: string
  confirm_password?: string
}

interface IChangePasswordModalProps {
  handleSubmit: (values: any) => void
  handleCancel: () => void
  showModal: boolean
  changePasswordButtonLoading: boolean
  type?: string
}

export const ChangePasswordModal: React.FC<IChangePasswordModalProps> = ({
  handleSubmit,
  handleCancel,
  showModal,
  changePasswordButtonLoading,
  type,
}) => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .matches(
        /^(?=.*[A-Z])(?=.*\d).{6,20}$/,
        t(
          "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
        )
      )
      .when(`id`, {
        is: (id: string | undefined) => id === undefined,
        then: yup.string().required(
          `${t("inputValidationMessage", {
            fieldName: `${t("Password")}`,
          })}`
        ),
      }),
    confirm_password: yup
      .string()
      .required(t("Please retype your password."))
      .oneOf([yup.ref("password")], t("Your password do not match.")),
  })
  const formik = useFormik<IPasswordReset>({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  return (
    <StyledModal
      title={t("Change password")}
      open={showModal}
      onCancel={handleCancel}
      footer={[]}
      className={"ant-modal"}
    >
      <ErrorLabel style={{ marginTop: -10 }}>
        <span
          className={`warning ${
            formik.touched.password &&
            formik.errors.password &&
            "validation-error"
          }`}
        >
          {t(
            "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
          )}
        </span>
      </ErrorLabel>
      <form onSubmit={formik.handleSubmit} className={"passwordModalForm"}>
        <InputField
          type={"password"}
          label={t("New password")}
          {...formik.getFieldProps("password")}
          height={"42px"}
          placeholder={t("Enter Password")}
          required
          maxLength={20}
          minLength={6}
        />
        <InputField
          type={"password"}
          label={t("Confirm password")}
          error={
            formik.touched.confirm_password && formik.errors.confirm_password
          }
          {...formik.getFieldProps("confirm_password")}
          height={"42px"}
          maxLength={20}
          minLength={6}
          required
          placeholder={t("Confirm password")}
        />

        <ModalFooter>
          <Button
            label={t("Cancel")}
            height={"50px"}
            key={"back"}
            backgroundColor={theme.background.colorSecondary4}
            onClick={handleCancel}
          >
            {"Cancel"}
          </Button>
          <Button
            type={type}
            label={t("Submit")}
            height={"50px"}
            htmlType={HtmlType.SUBMIT}
            loading={changePasswordButtonLoading}
          />
        </ModalFooter>
      </form>
    </StyledModal>
  )
}

export default ChangePasswordModal
