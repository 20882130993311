import React from "react"
import styled from "styled-components"
import { ConfigProvider, DatePicker } from "antd"
import jaJP from "antd/lib/locale/ja_JP"
import "moment/locale/ja"
import moment from "moment"
import { theme } from "../../../theme"
import { i18next } from "../../../i18n"
moment.locale("ja")

interface IDatePicker {
  showTime?: object | boolean
  width?: string
  label?: string
  required?: boolean
  placeholder?: string
  className?: string
  inputClassName?: string
  labelClassName?: string
  fontSize?: number
  height?: string
  format?: string
  borderRadius?: string
  allowClear?: boolean
  value?: any
  disabled?: boolean
  disabledDate?: (moment) => boolean
  style?: object
  onChange?: (moment, string) => void
  icon?: any
  iconClassName?: string
  picker?: "time" | "date" | "week" | "month" | "quarter" | "year"
  hideSuffixIcon?: boolean
}

const Wrapper = styled.div`
  display: contents;
  .icon {
    display: flex;
  }
  .ant-picker.ant-picker-disabled {
    background-color: transparent !important;
  }
  .label-container {
    display: flex;
    margin-bottom: 10px;
    .label {
      ${({ labelClassName }: IDatePicker) => {
        return labelClassName
          ? labelClassName
          : `${theme.typography.labelHeader}`
      }}
      opacity: 1;
      font-size: ${({ fontSize }: IDatePicker) => fontSize ?? "16px"};
      margin-right: 5px;
      font-weight: 500;
    }
    .required-label {
      ${theme.typography.labelHeader};
      color: ${theme.font.colorAlert};
      display: flex;
      justify-content: left;
    }
  }
`

const StyledDatePicker = styled(DatePicker)`
  width: ${({ width }: IDatePicker) => (width ? width : "auto")};
  height: ${({ height }: IDatePicker) => (height ? height : "auto")};
  border-radius: ${({ borderRadius }: IDatePicker) =>
    borderRadius ? borderRadius : "5px"};
  &:hover {
    border-color: ${theme.border.colorPrimary};
  }
  .ant-picker-input > input {
    font-size: 16px;

    @media (max-width: 786px) {
      font-size: 16px;
    }
  }
`

const DatePickerComponent: React.FC<IDatePicker> = ({
  placeholder,
  height,
  width,
  showTime,
  picker,
  className,
  format,
  disabled = false,
  allowClear = true,
  value,
  style,
  label,
  required,
  inputClassName,
  onChange,
  icon,
  iconClassName,
  disabledDate,
  hideSuffixIcon,
}) => {
  return (
    <Wrapper>
      <ConfigProvider locale={i18next.language === "ja" && jaJP}>
        <div className={className}>
          {label && (
            <div className={"label-container"}>
              <span className={"label"}>{label}</span>
              {required && <span className={"required-label"}>{"*"}</span>}
            </div>
          )}
          <StyledDatePicker
            {...(hideSuffixIcon ? { suffixIcon: null } : {})}
            disabledDate={disabledDate}
            height={height}
            width={width}
            showTime={showTime}
            placeholder={placeholder ?? ""}
            format={format}
            className={inputClassName}
            disabled={disabled}
            onChange={onChange}
            allowClear={allowClear}
            value={value}
            picker={picker}
            style={style}
          />
          {icon && <div className={`icon ${iconClassName}`}>{icon}</div>}
        </div>
      </ConfigProvider>
    </Wrapper>
  )
}

export { DatePickerComponent }
