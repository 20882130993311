import React from "react"
import styled from "styled-components"
import { theme } from "../../../theme"
import { useRouter } from "next/router"
import { LeftOutlined, PlusCircleOutlined } from "@ant-design/icons"
interface HeaderProps {
  backBtn?: boolean
  add?: boolean
  addLabel?: string
  title?: string
  actionUrl?: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
}
const Container = styled.div`
  display: flex;
  height: 64px;
  background: ${theme.background.colorSecondary1};
  border: 1px solid ${theme.border.colorSecondary1};
  align-items: center;
  justify-content: space-between;
  .header {
    ${theme.typography.header}
    height: 64px;
    align-items: center;
    display: flex;
  }
  .left {
    display: flex;
    align-items: center;
    height: 64px;
    margin-left: 50px;
    ${theme.typography.header}
    .back-btn {
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .right {
    ${theme.typography.label}
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-right: 50px;
  }
`
const Header: React.FC<HeaderProps> = ({
  backBtn,
  title,
  actionUrl,
  add,
  addLabel,
  onClick,
}) => {
  const router = useRouter()
  return (
    <Container>
      <div className={"left"}>
        {backBtn && (
          <div
            className={"back-btn"}
            onClick={() => (actionUrl ? router.push(actionUrl) : router.back())}
          >
            <LeftOutlined />
          </div>
        )}
        <span className={"header"}>{title}</span>
      </div>
      {add && (
        <div className={"right"} onClick={onClick}>
          <PlusCircleOutlined />
          {addLabel}
        </div>
      )}
    </Container>
  )
}

export { Header }
