import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js"
import { userPool } from "../cognito"

export const signInWithEmailAndPassword = async (
  email: string,
  password: string,
  onSuccess: (data: any) => any,
  onFailure: (data: any) => any
) => {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  })
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  })
  user.authenticateUser(authDetails, {
    onSuccess: onSuccess,
    onFailure: onFailure,
  })
}
