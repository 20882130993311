export const pagesname = [
  "webinars_key",
  "webinar-applicants_key",
  "knowhow_key",
  "material-applicants_key",
  "cases_key",
  "inquiries_key",
]

export const setPage = (key, val = 1) => {
  let pages = window.localStorage.getItem("page")
  pages = pages ? JSON.parse(pages) : {}
  pages[`${key}`] = val
  localStorage.setItem("page", JSON.stringify(pages))
  return true
}

export const getPage = (key) => {
  let page = window.localStorage.getItem("page")
  page = page ? JSON.parse(page) : {}
  return page[key] || "1"
}

export const setKeyword = (key, val = "") => {
  let keyword = window.localStorage.getItem("keyword")
  keyword = keyword ? JSON.parse(keyword) : {}
  keyword[`${key}_key`] = val
  localStorage.setItem("keyword", JSON.stringify(keyword))
  return true
}

export const getKeyword = (key) => {
  let keyword = window.localStorage.getItem("keyword")
  keyword = keyword ? JSON.parse(keyword) : {}
  return keyword[`${key}_key`] || ""
}

export const setFilter = (key, val = "") => {
  let filter = window.localStorage.getItem("filter")
  filter = filter ? JSON.parse(filter) : {}
  filter[`${key}_filter`] = val
  localStorage.setItem("filter", JSON.stringify(filter))
  return true
}

export const getFilter = (key) => {
  let filter = window.localStorage.getItem("filter")
  filter = filter ? JSON.parse(filter) : {}
  return filter[`${key}_filter`] || ""
}

export const getOnePageWithKeyword = (page) => {
  let keywords = window.localStorage.getItem("keyword")
  keywords = keywords ? JSON.parse(keywords) : {}

  let pages = window.localStorage.getItem("page")
  pages = pages ? JSON.parse(pages) : {}

  let url = `/${page}?page=${pages[page] || 1}`

  Object.keys(keywords)?.forEach((key) => {
    if (keywords[`${key}`]) {
      url += `&${key}=${escape(keywords[`${key}`])}`
    }
  })
  return url
}

export const getNestedPageKeyword = (page) => {
  let keywords = window.localStorage.getItem("keyword")
  keywords = keywords ? JSON.parse(keywords) : {}

  let pages = window.localStorage.getItem("page")
  pages = pages ? JSON.parse(pages) : {}

  let url = `/${page}?page=${pages[page] || 1}`

  Object.keys(keywords)?.forEach((key) => {
    if (keywords[`${key}`]) {
      // url += `&${key}=${escape(keywords[`${key}`])}`
      Object.keys(keywords[`${key}`]).forEach((k) => {
        url += `&${k}=${escape(keywords[key][k])}`
      })
    }
  })
  return url
}

export const setAllKeyword = (keyobj = {}, key = "", val = 1) => {
  localStorage.setItem("keyword", JSON.stringify(keyobj))
  if (key) {
    setPage(key, val)
  }
  return true
}

export const setSessionRoute = (pagename = "webinars", query) => {
  if (pagename == "/") {
    pagename = "webinars"
  }
  if (pagename !== "") {
    let url = `${pagename}?page=${query?.page || 1}`
    for (const page of pagesname) {
      if (query && query[`${page}`]) {
        url += `&${page}=${query[page]}`
      }
    }
    window.sessionStorage.setItem("page", url)
  }
  return true
}

export const getSessionRoute = () => {
  let route = window.sessionStorage.getItem("page")
  if (!route) {
    route = "webinars?page=1"
  }
  return route
}
