import { createContext } from "react"

type ContextProps = {
  loading: boolean
  user: any | null
  authenticated: boolean
  setUser: any
  isAdmin: boolean
  isSuperAdmin: boolean
  setIsAdmin: any
  setIsSuperAdmin: any
}

export const AuthContext = createContext<Partial<ContextProps>>({})

type AuthProviderProps = {
  loading: boolean
  user: any | null
  setUser: any
  isAdmin: boolean
  children: React.ReactNode
  isSuperAdmin: boolean
  setIsAdmin: any
  setIsSuperAdmin: any
}

export const AuthProvider = (props: AuthProviderProps) => {
  const {
    loading,
    user,
    setUser,
    children,
    isAdmin,
    isSuperAdmin,
    setIsAdmin,
    setIsSuperAdmin,
  } = props
  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated: user !== null,
        setUser,
        isAdmin,
        isSuperAdmin,
        setIsAdmin,
        setIsSuperAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
