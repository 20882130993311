import React, { useState } from "react"
import styled from "styled-components"
import { theme } from "../../../theme"
import { useRouter } from "next/router"
import { Layout, Menu } from "antd"
import { useTranslation } from "react-i18next"

interface SideBarComponentProps {
  tenant: Object
  children: any
  navItems: any
  type: string
}

const { Sider, Content } = Layout
const Container = styled.div<any>`
  height: 100vh;
  overflow: auto;
  background-color: ${theme.background.colorSecondary3};
`
const FormBody = styled.div<{ type: string }>`
  height: "100vh";
  .logo {
    background: ${theme.background.colorSecondary1};
    height: 64px;
    display: flex;
    color: ${theme.font.colorSecondary5};
    font-weight: 700;
    font-size: 16px;
    align-items: center;
    justify-content: center;
  }
  .logo-text {
    margin-left: 20px;
  }
  .logo-collapsed {
    background: ${theme.background.colorSecondary1};
    height: 64px;
    display: flex;
    color: ${theme.font.colorSecondary5};
    font-weight: 700;
    font-size: 16px;
    align-items: center;
    img {
      margin: auto;
    }
  }
  .ant-menu-inline-collapsed > li {
    padding-top: 9px !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff;
  }
  .ant-menu > .ant-menu-item-active :hover {
    color: ${theme.font.colorPrimary};
  }
  .ant-menu-item > .ant-menu-item-active ::before {
    content: "";
    color: ${theme.font.colorPrimary};
  }
  .menu-item > span {
    ${theme.typography.label}
  }
  .ant-menu {
    background: ${({ type }) =>
      type === "business"
        ? theme.background.lightOrange
        : theme.background.primaryBlue} !important;
  }
  .sidebar {
    background-color: ${({ type }) =>
      type === "business"
        ? theme.background.lightOrange
        : theme.background.primaryBlue};
  }
  .sidebar > .ant-layout-sider-children {
    height: 100vh;
  }
  .ant-menu-item {
    margin: 0px !important;
    height: 52px;
  }

  .ant-menu > .ant-menu-item {
    border-bottom: solid 1px ${theme.border.menuBorder};
  }
  .ant-menu > .ant-menu-item-active,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    position: relative;
    background: ${({ type }) =>
      type === "business"
        ? theme.background.darkOrange
        : theme.background.colorSecondary5};
    svg {
      path {
        fill: ${theme.background.colorSecondary1};
      }
    }
    .ant-menu-title-content {
      color: ${theme.font.colorSecondary};
    }
    &:after {
      content: none;
    }
  }
  .ant-layout-sider-trigger {
    background-color: ${({ type }) =>
      type === "business" && theme.background.darkOrange};
  }
  .loader {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
`

const SideBarComponent: React.FC<SideBarComponentProps> = ({
  tenant,
  children,
  navItems,
  type,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  // テスト用
  // const logoPath = "/assets/icons/sidebarLogo-1.svg"
  const logoPath = "/assets/icons/sidebarLogo-" + tenant?.["id"] + ".svg"
  return (
    <FormBody type={type}>
      <Layout>
        <Sider
          width={250}
          className={"sidebar"}
          breakpoint={"xl"}
          collapsedWidth={"60px"}
          collapsible={true}
          onCollapse={(collapsed) => {
            setCollapsed(collapsed)
          }}
        >
          {!collapsed ? (
            <div className={"logo"}>
              <img src={logoPath} width={46} height={40} />
              <div className={"logo-text"}>{tenant?.["name"]}</div>
            </div>
          ) : (
            <div className={"logo-collapsed"}>
              <img src={logoPath} width={46} height={40} />
            </div>
          )}

          <Menu
            color={"black"}
            mode={"inline"}
            selectedKeys={[router.pathname.split("/")[1]]}
            items={navItems}
          ></Menu>
        </Sider>
        <Layout>
          <Container>
            <Content>{children}</Content>
          </Container>
        </Layout>
      </Layout>
    </FormBody>
  )
}
export { SideBarComponent }
