import React from "react"
import { ConfigProvider, Table } from "antd"
import { SpinProps } from "antd/lib/spin"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import { TableLocale } from "antd/lib/table/interface"
import styled from "styled-components"
import jaJP from "antd/lib/locale/ja_JP"
import { theme } from "../../../theme"
import { i18next } from "../../../i18n"

const TableWrapper = styled(Table)<{ type: string }>`
  font-size: 16px;
  border: transparent;
  & thead > tr > th {
    background-color: ${({ type }) =>
      type === "business"
        ? theme.background.lightOrange
        : theme.border.colorSecondary2};
    height: 50px;
    color: ${theme.font.colorPrimary};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  & thead > tr > td {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.44px;
    color: ${theme.font.colorPrimary};
  }
  & .ant-table-row {
    height: 60.34px;
  }
  & .ant-table-row:nth-child(even) {
    background-color: ${theme.background.colorSecondary2};
  }
  & .ant-table-cell {
    padding-left: 23px !important;
  }
  .delete {
    color: red;
  }
  .action-icons {
    display: flex;
    justify-content: space-evenly;
    color: red;
    align-items: center;
    .anticon-delete {
      margin-top: 3px;
    }
  }
`

interface TabularProps {
  key?: string
  dataSource?: Record<string, unknown>[]
  columns?: object[]
  pagination?: any
  loading?: boolean | SpinProps
  size?: SizeType
  bordered?: boolean
  locale?: TableLocale
  rowKey?: string
  onChange?: any
  type?: string
}

const TableComponent: React.FC<TabularProps> = ({
  key,
  dataSource,
  columns,
  pagination,
  loading,
  size,
  bordered,
  locale,
  rowKey,
  onChange,
  type,
}) => {
  return (
    <ConfigProvider locale={i18next.language === "ja" && jaJP}>
      <TableWrapper
        type={type}
        key={key}
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        loading={loading}
        size={size}
        bordered={bordered}
        locale={locale}
        onChange={onChange}
        rowKey={rowKey}
      />
    </ConfigProvider>
  )
}

export { TableComponent }
