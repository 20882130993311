import axios from "axios"
import * as Sentry from "@sentry/node"
import { userPool } from "./cognito"
import { CognitoUserSession } from "amazon-cognito-identity-js"
axios.defaults.responseType = "json"

/**
 * axios instance
 */

axios.defaults.responseType = "json"

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Time-Zone": `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
  },
})

const getToken = async () => {
  const user = userPool.getCurrentUser()

  if (user) {
    try {
      const session = await new Promise<CognitoUserSession>(
        (resolve, reject) => {
          user.getSession((err: any, session: CognitoUserSession) => {
            if (!err && session.isValid()) {
              resolve(session)
            } else {
              reject(err)
            }
          })
        }
      )
      const idToken = session.getIdToken().getJwtToken()
      return idToken
    } catch (error) {
      return null
    }
  } else {
    return null
  }
}

API.interceptors.request.use(
  async (config) => {
    if (typeof sessionStorage !== "undefined") {
      if (sessionStorage.getItem("X-Agent-User")) {
        config.headers["X-Agent-User"] = sessionStorage?.getItem("X-Agent-User")
      }
    }
    const token = await getToken()
    if (token) {
      config.headers["Authorization"] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

API.interceptors.response.use(
  (response) => {
    return response?.data
  },
  async (error) => {
    Sentry.captureException(error)
    return Promise.reject(error.response)
  }
)

export { API }
