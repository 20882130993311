import { Input } from "antd"
import React, { useCallback } from "react"
import styled from "styled-components"
import { theme } from "../../../theme"

interface InputProps {
  showCounting?: boolean
  value?: any
  maxLength?: number
  minLength?: number
  placeholder?: string
  type?: string
  width?: number
  className?: string
  labelClassName?: string
  inputClassName?: string
  errorClassName?: string
  label?: string
  required?: boolean
  borderRadius?: string
  height?: string
  error?: string
  onChange?: any
  onBlur?: any
  rows?: number
  prefix?: React.ReactNode
  name?: string
  icon?: any
  disabled?: boolean
  iconClassName?: string
  pattern?: any
  fullwidth?: string
  subLabel?: string
}

const CountingStyled = styled.div<{ isRed: boolean }>`
  font-size: 12px;
  color: ${({ isRed }) => isRed && theme.font.colorAlert};
`
const InputStyled = styled(Input)`
  border-radius: ${({ borderRadius }: InputProps) => borderRadius || "4px"};
  height: ${({ height }: InputProps) => height || "60px"};
`
const PasswordStyled = styled(InputStyled.Password)`
  border-radius: ${({ borderRadius }: InputProps) => borderRadius || "4px"};
  height: ${({ height }: InputProps) => height || "60px"};
`

const TextFieldWrapperStyled = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  display: flex;
  flex-direction: column;
  width: ${({ width }: InputProps) => {
    return width ? width : "auto"
  }};
  .ant-input[disabled] {
    background-color: transparent;
  }
  border-color: ${theme.border.colorSecondary3};
  .ant-input {
    &:focus,
    &:hover,
    &:active {
      border-color: ${theme.border.colorSecondary7};
    }
  }
  .label-container {
    display: flex;
    margin-bottom: 10px;
    .label {
      ${({ labelClassName }: InputProps) => {
        return labelClassName
          ? labelClassName
          : `${theme.typography.labelHeader}`
      }}

      opacity: 1;
      margin-right: 5px;
      font-weight: 500;
    }
    .required-label {
      ${theme.typography.labelHeader};
      color: ${theme.font.colorAlert};
      display: flex;
      justify-content: left;
    }
    .sub-label {
      ${theme.typography.subLabel};
      color: ${theme.font.colorWarning};
      .required-sub-label {
        color: ${theme.font.colorAlert};
      }
    }
  }
`

export const ErrorLabel = styled.div<any>`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  .error {
    ${theme.typography.subLabel};
    color: ${theme.font.colorAlert} !important;
    ${({ errorClassName }: InputProps) => {
      return errorClassName ? errorClassName : ` ${theme.typography.header}`
    }};
    margin-top: 2px;
    margin-left: 2px;
  }
  .warning {
    ${theme.typography.subLabel};
    color: ${theme.font.colorWarning};
    margin-top: 2px;
    margin-left: 2px;
  }
  .validation-error {
    color: ${theme.font.colorAlert};
  }
`

const InputField: React.FC<InputProps> = ({
  showCounting,
  value,
  maxLength,
  minLength,
  type,
  className,
  onBlur,
  label,
  required,
  error,
  onChange,
  placeholder,
  errorClassName,
  labelClassName,
  inputClassName,
  iconClassName,
  rows,
  prefix,
  name,
  icon,
  disabled,
  subLabel,
  ...rest
}) => {
  const { TextArea } = Input
  const countingUI = useCallback(() => {
    return (
      showCounting && (
        <CountingStyled isRed={value?.length > maxLength}>
          {`${
            value ? value.toString().length.toLocaleString() : 0
          }/${maxLength.toLocaleString()}`}
        </CountingStyled>
      )
    )
  }, [value, maxLength])

  const countNum = useCallback(() => {
    return (
      showCounting && (
        <CountingStyled isRed={value?.toString().length >= maxLength}>
          {`${
            value ? value.toString().length : 0
          }/${maxLength.toLocaleString()}`}
        </CountingStyled>
      )
    )
  }, [value, maxLength])

  const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = /^[\d+]*$/.test(event.target.value)
    if (isValid) {
      onChange(event.target.value)
    }
  }

  const textFieldUI = () => {
    switch (type) {
      case "password":
        return (
          <TextFieldWrapperStyled className={className}>
            {label && (
              <div className={"label-container"}>
                <span className={"label" || labelClassName}>{label}</span>
                {required && <span className={"required-label"}>{"*"}</span>}
                {subLabel && (
                  <span className={"sub-label"}>
                    {"("}
                    <span className={"required-sub-label"}>{"*"}</span>
                    {subLabel}
                    {")"}
                  </span>
                )}
              </div>
            )}
            <PasswordStyled
              {...rest}
              type={"password"}
              className={inputClassName}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              minLength={minLength}
            />
            <ErrorLabel>
              {!disabled ? countingUI() : null}
              {error ? (
                <div className={"error" || errorClassName}>{error}</div>
              ) : (
                <div />
              )}
            </ErrorLabel>
            {icon && icon}
          </TextFieldWrapperStyled>
        )
      case "textarea":
        return (
          <TextFieldWrapperStyled className={className}>
            {label && (
              <div className={"label-container"}>
                <span className={"label"}>{label}</span>
                {required && <span className={"required-label"}>{"*"}</span>}
              </div>
            )}
            <InputStyled
              as={TextArea}
              value={value}
              onBlur={onBlur}
              className={inputClassName}
              autoSize={{ minRows: rows }}
              placeholder={placeholder}
              onChange={onChange}
              maxLength={maxLength}
              name={name}
              {...rest}
            />
            <ErrorLabel>
              {error ? <span className={"error"}>{error}</span> : <div />}
              {!disabled ? countingUI() : null}
            </ErrorLabel>
            {icon && icon}
          </TextFieldWrapperStyled>
        )
      case "number":
        return (
          <TextFieldWrapperStyled className={className}>
            {label && (
              <div className={"label-container"}>
                <span className={"label"}>{label}</span>
                {required && <span className={"required-label"}>{"*"}</span>}
              </div>
            )}
            <InputStyled
              prefix={prefix}
              value={value}
              type={"number"}
              className={inputClassName}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
              maxLength={12}
              {...rest}
            />
            <ErrorLabel>
              {error ? <div className={"error"}>{error}</div> : <div />}
              {!disabled ? countNum() : null}
            </ErrorLabel>
            {icon && icon}
          </TextFieldWrapperStyled>
        )
      case "phone":
        return (
          <TextFieldWrapperStyled className={className}>
            {label && (
              <div className={"label-container"}>
                <span className={"label"}>{label}</span>
                {required && <span className={"required-label"}>{"*"}</span>}
              </div>
            )}
            <InputStyled
              onChange={handlePhone}
              value={value}
              name={name}
              onBlur={onBlur}
              disabled={disabled}
              className={inputClassName}
              placeholder={placeholder}
              maxLength={maxLength}
              {...rest}
            />

            <ErrorLabel className={iconClassName}>
              {error ? <span className={"error"}>{error}</span> : <div />}
              {!disabled ? countingUI() : null}
              {icon && <div>{icon}</div>}
            </ErrorLabel>
          </TextFieldWrapperStyled>
        )
      default:
        return (
          <TextFieldWrapperStyled className={className}>
            {label && (
              <div className={"label-container"}>
                <span className={"label"}>{label}</span>
                {required && <span className={"required-label"}>{"*"}</span>}
              </div>
            )}
            <InputStyled
              onChange={onChange}
              value={value}
              name={name}
              onBlur={onBlur}
              disabled={disabled}
              className={inputClassName}
              placeholder={placeholder}
              maxLength={maxLength}
              {...rest}
            />

            <ErrorLabel className={iconClassName}>
              {error ? <span className={"error"}>{error}</span> : <div />}
              {!disabled ? countingUI() : null}
              {icon && <div>{icon}</div>}
            </ErrorLabel>
          </TextFieldWrapperStyled>
        )
    }
  }
  return textFieldUI()
}

export { InputField }
