import { ConfigProvider, Select } from "antd"
import { LabeledValue } from "antd/lib/select"
import styled from "styled-components"
import { theme } from "../../../theme"
import i18next from "i18next"
import moment from "moment"
import jaJP from "antd/lib/locale/ja_JP"

moment.locale("ja")

export interface ISelect {
  label?: string
  name?: string
  bordered?: boolean
  onChange?: (values) => void
  $isLoading?: boolean
  width?: number
  height?: number
  fontSize?: string
  loading?: boolean
  className?: string
  options?: any[]
  required?: boolean
  onBlur?: any
  placeholder?: string
  error?: string
  $wrapperWidth?: number
  $containerMargin?: string
  value?: any
  allowClear?: boolean
  inputClassName?: string
  labelClassName?: string
  disabled?: boolean
  hideSuffixIcon?: boolean
  defaultValue?:
    | string
    | string[]
    | number
    | number[]
    | LabeledValue
    | LabeledValue[]
  errorclassname?: string
  icon?: any
  iconClassName?: string
  showSearch?: any
  filterOption?: any
  onSearch?: any
  dropdownRender?: any
  optionFilterProp?: any
  open?: boolean
  onDropdownVisibleChange?: any
}

const StyledSelect = styled(({ dark, bgColor, error, ...props }) => {
  return (
    <Select
      getPopupContainer={(trigger) => trigger.parentNode}
      {...dark}
      {...props}
      {...error}
      bgcolor={bgColor}
    />
  )
})`
  width: ${({ width }: ISelect) => (width ? `${width}px` : "100%")};
  min-width: ${({ width }: ISelect) => (width ? `${width}px` : "100%")};
  border: 1px solid ${theme.border.colorSecondary1};
  height: ${({ height }: ISelect) => (height ? `${height}px` : "50px")};
  border-radius: 5px;
  display: flex;
  align-items: center;

  & .ant-select-selection-item {
    font-size: 14px;
  }

  @media (max-width: 786px) {
    & .ant-select-selection-item {
      font-size: 16px;
    }
  }
`

const Container = styled.div`
  min-width: ${({ $wrapperWidth }: ISelect) => `${$wrapperWidth}px`};
  margin: ${({ $containerMargin }: ISelect) => `${$containerMargin}`};
  .label-container {
    display: flex;
    margin-bottom: 10px;
    .label {
      ${({ labelClassName }: ISelect) => {
        return labelClassName
          ? labelClassName
          : `${theme.typography.labelHeader}`
      }}
      opacity: 1;
      font-size: ${({ fontSize }: ISelect) => fontSize ?? "16px"};
      margin-right: 5px;
      font-weight: 500;
    }
    .required-label {
      ${theme.typography.labelHeader};
      color: ${theme.font.colorAlert};
      display: flex;
      justify-content: left;
    }
  }
  .error-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    .error {
      ${({ errorclassname }: ISelect) => {
        return errorclassname ? errorclassname : ` ${theme.typography.header}`
      }}
      color: ${theme.font.colorAlert};
      margin-top: 2px;
      margin-left: 2px;
    }
  }
`
const SelectComponent = (props: ISelect) => {
  const { Option } = Select
  const {
    label,
    bordered = false,
    $isLoading,
    loading,
    width,
    placeholder,
    className,
    options,
    required,
    onChange,
    onBlur,
    inputClassName,
    labelClassName,
    allowClear,
    error,
    height,
    value,
    fontSize,
    disabled,
    defaultValue,
    name,
    hideSuffixIcon,
    icon,
    iconClassName,
    showSearch,
    filterOption,
    onSearch,
    dropdownRender,
    optionFilterProp,
    open,

    onDropdownVisibleChange,
    ...rest
  } = props
  return (
    <ConfigProvider locale={i18next.language === "ja" && jaJP}>
      <Container className={className} fontSize={fontSize} {...rest}>
        {label && (
          <div className={"label-container"}>
            <span className={labelClassName ? labelClassName : "label"}>
              {label}
            </span>
            {required && <span className={"required-label"}>{"*"}</span>}
          </div>
        )}
        <StyledSelect
          suffixIcon={hideSuffixIcon && null}
          bordered={bordered}
          onChange={onChange}
          $isLoading={$isLoading}
          loading={loading}
          options={options}
          width={width}
          onBlur={onBlur}
          height={height}
          placeholder={placeholder}
          value={value}
          allowClear={allowClear}
          disabled={disabled}
          className={
            disabled ? `ant-select-disabled ${inputClassName}` : inputClassName
          }
          defaultValue={defaultValue}
          name={name}
          optionFilterProp={optionFilterProp}
          open={open}
          onDropdownVisibleChange={onDropdownVisibleChange}
          showSearch={showSearch}
          filterOption={filterOption}
          onSearch={onSearch}
          dropdownRender={dropdownRender}
          {...rest}
        >
          {options &&
            options.map((option: any, index: number) =>
              typeof option == "object" ? (
                <Option
                  key={index}
                  value={option.value}
                  disabled={option.disabled === true}
                >
                  {option.label}
                </Option>
              ) : (
                <Option key={index} value={option}>
                  {option}
                </Option>
              )
            )}
        </StyledSelect>
        <div className={iconClassName}>{icon && icon}</div>
        <div className={"error-wrapper"}>
          {error ? <span className={"error"}>{error}</span> : <div />}
        </div>
      </Container>
    </ConfigProvider>
  )
}

export { SelectComponent }
