import { message, Spin } from "antd"
import { AxiosResponse } from "axios"
import { t } from "i18next"
import { useState } from "react"
import styled from "styled-components"
import { theme } from "../../../theme"
import { API } from "../../../api"

const StyledDiv = styled.div`
  width: 132px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    cursor: pointer;
    font-size: 16px;
    color: ${theme.font.colorPrimary};
    text-decoration: underline;
    @media (max-width: 1300px) {
      font-size: 14px;
    }
  }

  .disabled {
    cursor: not-allowed !important;
    color: ${theme.background.colorSecondary} !important;
  }
`

interface ICsvDownload {
  primaryApiUrlEndpoint: string
  secondaryApiUrlEndpoint?: string
  queryParameters?: IQueryParameters
  downloadFileName: string
  title?: string
  attachTimeStamp?: boolean
  enabled?: boolean
}

interface IQueryParameters {
  start_date?: string
  end_date?: string
  event_id?: string
  business_type?: string
  sort?: string
  order?: string
  date?: string
  facility_id?: number
  keyword?: string
  resident_id?: string
}

export const CsvDownload: React.FC<ICsvDownload> = ({
  primaryApiUrlEndpoint,
  secondaryApiUrlEndpoint,
  queryParameters,
  downloadFileName,
  title = t("CSV download"),
  attachTimeStamp = true,
  enabled = true,
}) => {
  const [loading, setLoading] = useState(false)

  const handleApiCall = async (urlEndpoint: string) => {
    setLoading(true)
    try {
      const data: { file_path: string; execution_arn: string } = await API.get(
        urlEndpoint,
        {
          params: queryParameters,
        }
      )
      return data
    } catch {
      setLoading(false)
    }
  }

  const handleApiCallForProgressStatus = async (
    urlEndpoint: string,
    filePath: string,
    executionArn: string
  ) => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const response: { isCompleted: boolean; status: string } = await API.get(
        urlEndpoint,
        {
          params: secondaryApiUrlEndpoint
            ? { file_path: filePath, execution_arn: executionArn }
            : queryParameters,
        }
      )

      if (!response?.isCompleted && response?.status !== "In progress.") {
        return response
      }
    }
  }

  const handleClientsDownload = async (
    urlEndpoint: string,
    fileName: string,
    filePath?: string,
    executionArn?: string
  ) => {
    setLoading(true)
    try {
      const response = await handleApiCallForProgressStatus(
        urlEndpoint,
        filePath,
        executionArn
      )

      const url = window.URL.createObjectURL(
        new Blob([response as unknown as string], { type: "text/csv" })
      )
      const link = document.createElement("a")
      link.href = url

      const nameSplitted = fileName.split(".")

      // if there is .csv at the end remove it
      if (nameSplitted[nameSplitted.length - 1] === "csv") {
        nameSplitted.pop()
        fileName = nameSplitted.join()
      }

      // if there is no file name compulsorily assign time stamp to file name
      if (fileName.length === 0) {
        fileName = new Date().toISOString()
      } else if (attachTimeStamp) {
        // if file name has value add time stamp conditionally
        // by default adds timestamp unless specified not to
        fileName = `${fileName}_${new Date().toISOString()}`
      }

      link.setAttribute("download", `${fileName}.csv`)
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      message.error(t("csvDownloadFailed"))
    }
    setLoading(false)
  }

  return (
    <StyledDiv>
      {loading ? (
        <Spin />
      ) : (
        <a
          onClick={
            !enabled
              ? null
              : async () => {
                  if (secondaryApiUrlEndpoint) {
                    const response = await handleApiCall(primaryApiUrlEndpoint)

                    handleClientsDownload(
                      secondaryApiUrlEndpoint,
                      downloadFileName,
                      response?.file_path,
                      response?.execution_arn
                    )
                  } else {
                    handleClientsDownload(
                      primaryApiUrlEndpoint,
                      downloadFileName
                    )
                  }
                }
          }
          className={!enabled ? "disabled" : ""}
        >
          {title}
        </a>
      )}
    </StyledDiv>
  )
}
