const heading = {
  fontWeight: 400,
  fontSize: "32px",
  lineHeight: "39px",
  fontFamily: "'Inter',sans-serif",
}

const labelHeader = {
  fontSize: "22px",
  fontWeight: 400,
  lineHeight: "27px",
  fontFamily: "'Inter',sans-serif",
}
const header = {
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "24px",
  fontFamily: "'Noto Sans JP',sans-serif",
}
const label = {
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "'Noto Sans JP',sans-serif",
}
const subLabel = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "24px",
  fontFamily: "'Noto Sans JP',sans-serif",
}

export const theme = {
  background: {
    colorPrimary: "#1B6335",
    colorSecondary: "#E7E9ED",
    colorSecondary1: "#FFFFFF",
    colorSecondary2: "#F5F5F5",
    colorSecondary3: "#F5F8FF",
    colorSecondary4: "#9E9590",
    colorSecondary5: "#005FAF",
    colorSecondary6: "#40a9ff",
    cancelRedColor: "#d9363e;",
    blackColor: "#000000",
    primaryBlue: "rgba(202, 233, 241, 0.9)",
    blueColor: "#005FAF",
    disabledColor: "#7987a478",
    darkOrange: "#ff6038",
    lightOrange: "#f6e1bb",
    orange: "#ffa500",
  },
  border: {
    colorPrimary: "#8C8989",
    colorSecondary: "#E7E9ED",
    colorSecondary1: "#dedbd5",
    colorSecondary2: "#cae9f1",
    colorSecondary3: "#D9D9D9",
    colorSecondary4: "#ccc",
    colorSecondary5: "#65B798",
    colorSecondary6: "#d9d9d9",
    colorSecondary7: "#8b94a5",
    colorAlert: "#EE3636",
    radiusPrimary: "10px",
    menuBorder: "#ffffff",
    blackColor: "#000000",
    blueColor: "#005FAF",
  },
  shadow: {
    colorPrimary: "rgba(0, 0, 0, 0.25)",
  },
  font: {
    colorPrimary: "#000000",
    colorSecondary: "#FFFFFF",
    colorSecondary1: "#65B798",
    colorSecondary2: "#595959",
    colorSecondary3: "#d9d9d9",
    colorSecondary4: "#1890ff",
    colorSecondary5: "#F58344",
    colorSecondary6: "#1890ff",
    colorAlert: "#EE3636",
    colorWarning: "#666666",
    lightBlack: "#2d2525",
  },
  svg: {
    colorPrimary: "#8c8989",
  },
  typography: {
    heading,
    header,
    labelHeader,
    label,
    subLabel,
  },
}
