import React from "react"
import styled from "styled-components"

import Image from "next/image"
import { theme } from "../../../theme"
import { DASHBOARD_TYPE } from "../../../utils"
interface IconCardProp {
  label?: string
  icon?: any
  valueContent?: any
  cardHeight?: number
  iconHeight?: number
  iconWidth?: number
  cardWidth?: number
  type?: string
}

const IconCard = styled.div`
  display: flex;
  background: ${theme.background.colorSecondary1};
  border: 4px solid
    ${({ type }) =>
      type === DASHBOARD_TYPE.BUSINESS
        ? theme.background.lightOrange
        : theme.border.colorSecondary2} !important;
  align-items: center;
  justify-content: flex-start;
  padding: 0 44px;
  height: ${({ cardHeight }: IconCardProp) => {
    return cardHeight ? cardHeight + "px" : "134px"
  }};
  min-width: ${({ cardWidth }: IconCardProp) => {
    return cardWidth ? cardWidth + "px" : "279px"
  }};

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-left: 8px;
    align-items: center;

    &--label {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
    &--value {
      font-weight: 700;
      font-size: 22px;
      line-height: 22px;
      margin-top: 25px;
      white-space: nowrap;
    }
  }
`

const IconCardComponent: React.FC<IconCardProp> = ({
  label,
  icon,
  valueContent,
  iconHeight,
  iconWidth,
  type,
  ...rest
}) => {
  return (
    <IconCard {...rest} type={type}>
      <div className={"icon"}>
        {icon == null ? (
          <Image
            src={icon ?? "/users.svg"}
            width={iconWidth ?? 60}
            height={iconHeight ?? 60}
          />
        ) : (
          icon
        )}
      </div>
      <div className={"content"}>
        <div className={"content--label"}>{label}</div>

        <div className={"content--value"}>{valueContent}</div>
      </div>
    </IconCard>
  )
}

export { IconCardComponent }
